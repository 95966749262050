<template>
	<div>
		<van-nav-bar title="查看合作方式" left-arrow border fixed placeholder safe-area-inset-top @click-left="onClickLeft" />
		<!-- 提供发票--企业 -->
		<div v-if="choice==0">
			<van-tabs v-model="active" title-inactive-color="#CFCFCE" title-active-color="#F15223">
				<van-tab title="合作说明">
					<div class="padding">
						<div class="flex" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">合作方式</div>
						</div>
						<div class="list-strong padding mt">企业</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">合作双方的权利和义务</div>
						</div>
						<div class="list-strong padding flex mt" style="align-items: center;">
							<div class="list-dian"></div>
							<div>在平台提供真实的企业资质信息进行认证</div>
						</div>
						<div class="list-strong padding flex mt" style="align-items: center;">
							<div class="list-dian"></div>
							<div>平台将与您认证的企业进行服务费的结算</div>
						</div>
						<div class="list-strong padding flex mt" style="align-items: center;">
							<div class="list-dian"></div>
							<div>申请服务费时，请先按照要求开具发票，开票主体与您认证的企业信息一致</div>
						</div>
						<div class="list-strong padding flex mt" style="align-items: center;">
							<div class="list-dian"></div>
							<div>由您的企业自行处理收入的申报和税费缴纳</div>
						</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">合作条件</div>
						</div>
						<div class="list-strong padding flex mt" style="align-items: center;">
							<div class="list-dian"></div>
							<div>已有稳定的企业，不会发生变更</div>
						</div>
						<div class="list-strong padding flex mt" style="align-items: center;">
							<div class="list-dian"></div>
							<div>您的企业支持开具“服务费”类型的增值税普通发票或者专用发票</div>
						</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">服务费申请方式</div>
						</div>
						<div class="list-strong padding flex mt" style="align-items: center;">
							<div class="list-dian"></div>
							<div>“服务费”经平台审核通过后，开具发票并邮寄发票</div>
						</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">服务费发放时间</div>
						</div>
						<div class="list-strong padding flex mt" style="align-items: center;">
							<div class="list-dian"></div>
							<div>平台收到发票核对无误即发放至您企业的对公账户</div>
						</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">综合手续费</div>
						</div>
						<div class="list-strong padding flex mt" style="align-items: center;">
							<div class="list-dian"></div>
							<div>无</div>
						</div>


					</div>
				</van-tab>
				<van-tab title="企业认证信息">
					<div class="padding" v-if="information.length!=''">
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">企业名称</div>
						</div>
						<div class="list-strong padding mt">{{information.auth_info.company_name}}</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">公司地址</div>
						</div>
						<div class="list-strong padding mt">{{information.auth_info.company_address}}</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">联系电话</div>
						</div>
						<div class="list-strong padding mt">{{information.auth_info.telephone}}</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">社会信用代码</div>
						</div>
						<div class="list-strong padding mt">{{information.auth_info.company_icode}}</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">法人姓名</div>
						</div>
						<div class="list-strong padding mt">{{information.auth_info.legal_name}}</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">法人身份证号</div>
						</div>
						<div class="list-strong padding mt">{{information.auth_info.id_card}}</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">法人身份证</div>
						</div>
						<div class="mt">
							<div class="flex">
								<div class="list-img" @click="onImagePreview(0)">
									<van-image width="100%" height="100%" fit="cover"
										:src="information.auth_info.auth_photos[0]" />
								</div>
								<div class="list-img ml" @click="onImagePreview(1)">
									<van-image width="100%" height="100%" fit="cover"
										:src="information.auth_info.auth_photos[1]" />
								</div>
							</div>
							<div class="list-img mt" @click="onImagePreview(2)">
								<van-image width="100%" height="100%" fit="cover"
									:src="information.auth_info.auth_photos[2]" />
							</div>
						</div>

					</div>
				</van-tab>
			</van-tabs>
		</div>
		<!-- 提供发票--个体 -->
		<div v-if="choice==1">
			<van-tabs v-model="active" title-inactive-color="#CFCFCE" title-active-color="#F15223">
				<van-tab title="合作说明">
					<div class="padding">
						<div class="flex" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">合作方式</div>
						</div>
						<div class="list-strong padding mt">个体工商户</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">合作条件</div>
						</div>
						<div class="list-strong padding flex mt" style="align-items: center;">
							<div class="list-dian"></div>
							<div>已有稳定的个体工商户，不会发生变更</div>
						</div>
						<div class="list-strong padding flex mt" style="align-items: center;">
							<div class="list-dian"></div>
							<div>您的个体工商户支持开具“服务费”类型的增值税普通或者专用发票</div>
						</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">服务费申请方式</div>
						</div>
						<div class="list-strong padding flex mt" style="align-items: center;">
							<div class="list-dian"></div>
							<div>“服务费”经平台审核通过后，开具发票并邮寄发票</div>
						</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">服务费发放时间</div>
						</div>
						<div class="list-strong padding flex mt" style="align-items: center;">
							<div class="list-dian"></div>
							<div>平台收到发票核对无误即发放至您个体工商户的对公账户</div>
						</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">综合手续费</div>
						</div>
						<div class="list-strong padding flex mt" style="align-items: center;">
							<div class="list-dian"></div>
							<div>无</div>
						</div>

					</div>
				</van-tab>
				<van-tab title="个体户认证信息">
					<div class="padding" v-if="information!=''">
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">个体户名称</div>
						</div>
						<div class="list-strong padding mt">{{information.auth_info.company_name}}</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">经营地址</div>
						</div>
						<div class="list-strong padding mt">{{information.auth_info.company_address}}</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">联系电话</div>
						</div>
						<div class="list-strong padding mt">{{information.auth_info.telephone}}</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">纳税人识别号</div>
						</div>
						<div class="list-strong padding mt">{{information.auth_info.company_icode}}</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">凭证照片</div>
						</div>
						<div class="list-strong padding mt">{{information.auth_info.legal_name}}</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">法人姓名</div>
						</div>
						<div class="list-strong padding mt">{{information.auth_info.id_card}}</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">法人身份证</div>
						</div>
						<div class="mt">
							<div class="flex">
								<div class="list-img" @click="onImagePreview(0)">
									<van-image width="100%" height="100%" fit="cover"
										:src="information.auth_info.auth_photos[0]" />
								</div>
								<div class="list-img ml" @click="onImagePreview(1)">
									<van-image width="100%" height="100%" fit="cover"
										:src="information.auth_info.auth_photos[1]" />
								</div>
							</div>
							<div class="list-img mt" @click="onImagePreview(2)">
								<van-image width="100%" height="100%" fit="cover"
									:src="information.auth_info.auth_photos[2]" />
							</div>
						</div>

					</div>
				</van-tab>
			</van-tabs>
		</div>
		<!-- 无法提供发票 -->
		<div v-if="choice==2">
			<van-tabs v-model="active" title-inactive-color="#CFCFCE" title-active-color="#F15223">
				<van-tab title="合作说明">
					<div class="padding">
						<div class="flex" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">合作方式</div>
						</div>
						<div class="list-strong padding mt">个人</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">合作双方的权利和义务</div>
						</div>
						<div class="list-strong padding flex mt" style="align-items: center;">
							<div class="list-dian"></div>
							<div>在平台提供真实的个人信息进行认证</div>
						</div>
						<div class="list-strong padding flex mt" style="align-items: center;">
							<div class="list-dian"></div>
							<div>通过与平台合作的第三方机构进行签约</div>
						</div>
						<div class="list-strong padding flex mt" style="align-items: center;">
							<div class="list-dian"></div>
							<div>“服务费”平台审核通过后，由第三方机构代为报税纳税和发放服务费</div>
						</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">月服务费申请额度</div>
						</div>
						<div class="list-strong padding flex mt" style="align-items: center;">
							<div class="list-dian"></div>
							<div>10000元</div>
						</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">综合服务费</div>
						</div>
						<div class="list-strong padding flex mt" style="align-items: center;">
							<div class="list-dian"></div>
							<div>月服务费申请总额小于等于1000时无综合服务费，月申请服务费总额 大于1000时按月服务费申请总额的7%收取</div>
						</div>
					</div>
				</van-tab>
				<van-tab title="个人认证信息">
					<div class="padding" v-if="information.length!=''">
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">真实姓名</div>
						</div>
						<div class="list-strong padding mt">{{information.auth_info.real_name}}</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">身份证号</div>
						</div>
						<div class="list-strong padding mt">{{information.auth_info.id_card }}</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">银行账号</div>
						</div>
						<div class="list-strong padding mt">{{information.bank_info.account_num}}</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">开户银行</div>
						</div>
						<div class="list-strong padding mt">{{information.bank_info.account_bank}}</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">手机号码</div>
						</div>
						<div class="list-strong padding mt">{{information.auth_info.phone}}</div>
						<div class="flex mt" style="align-items: center;">
							<div class="list-left"></div>
							<div class="list-text">身份证照片</div>
						</div>
						<div class="mt">
							<div class="flex">
								<div class="list-img" @click="onPersonalImagePreview(0)">
									<van-image width="100%" height="100%" fit="cover"
										:src="information.auth_info.id_card_front" />
								</div>
								<div class="list-img ml" @click="onPersonalImagePreview(1)">
									<van-image width="100%" height="100%" fit="cover"
										:src="information.auth_info.id_card_back" />
								</div>
							</div>
						</div>

					</div>
				</van-tab>
			</van-tabs>
		</div>

		<div class="padding"></div>
	</div>
</template>

<script>
	import {
		ImagePreview
	} from 'vant';
	export default {
		data() {
			return {
				active: 0,
				choice: 2, //0==企业，1==个人  2==无法提供发票
				openid: 2,
				login_token: localStorage.getItem("login_token"),
				information: '', //认证信息
			}
		},
		mounted() {
			var _this = this;
			_this.choice = _this.$route.query.choice
			var url = '';
			if (_this.$route.query.choice != 2) { //企业
				url = 'users/api.finance/get_company_auth_info';
			} else {
				url = 'users/api.finance/get_person_auth_info';
			}
			_this.$toast.loading({
				message: '加载中...',
				forbidClick: true,
				duration: 0
			});
			var myData = new URLSearchParams();
			myData.append("openid", _this.openid);
			myData.append("login_token", _this.login_token);
			_this.$http({
				method: 'post',
				url: url,
				data: myData
			}).then((res) => {
				_this.$toast.clear();
				if (res.data.code == 0) {
					if (_this.$route.query.choice != 2) {
						var str = res.data.auth_info.auth_photos
						var auth_photos = str.split(",")
						res.data.auth_info.auth_photos = auth_photos;
					}

					_this.information = res.data
					console.log(res.data)

				} else if (res.data.code == -100) {
					_this.$dialog.alert({
						title: '提示',
						message: '登录过期，请重新登录',
					}).then(() => {
						_this.$router.push({
							path: "/login"
						})
					});

				} else {
					_this.$toast(res.data.msg);
				}
			}).catch(() => {
				_this.$toast('网络请求失败');
			});

		},
		methods: {
			//返回
			onClickLeft() {
				console.log(window.history.length)
				if (window.history.length <= 1) {
					this.$router.push({
						path: '/'
					})
					return false
				} else {
					this.$router.go(-1)
				}
			},
			//图片预览
			onImagePreview(e) {
				var _this = this;
				ImagePreview({
					images: _this.information.auth_info.auth_photos,
					startPosition: e,
				});
			},
			//没有发票的凭证预览
			onPersonalImagePreview(e) {
				var _this = this;
				ImagePreview({
					images: [_this.information.auth_info.id_card_front, _this.information.auth_info.id_card_back],
					startPosition: e,
				});
			}
		},
		created(){
			this.openid = this.$store.state.openid
		}
	}
</script>
<style scoped="scoped">
	.ml {
		margin-left: 0.3rem;
	}

	.list-img {
		width: 3.14rem;
		height: 2.32rem;
	}

	.list-dian {
		width: 0.08rem;
		height: 0.08rem;
		background-color: #D7B6B9;
		border-radius: 50%;
		margin-right: 0.2rem;
	}

	.mt {
		margin-top: 0.3rem;
	}

	.list-strong {
		background-color: #FEF1F2;
		border-radius: 0.2rem;
		color: #D7B6B9;
		font-size: 0.26rem;
	}

	.list-text {
		font-size: 0.3rem;
		margin-left: 0.2rem;
	}

	.list-left {
		width: 0.06rem;
		height: 0.2rem;
		background-color: #F15223;
	}

	.padding {
		padding: 0.3rem;
	}

	.flex {
		display: flex;
	}
</style>
